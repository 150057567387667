import { default as about_45passPl1YGI9HrmMeta } from "/codebuild/output/src2057537274/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenaviEYy8zFpFa9Meta } from "/codebuild/output/src2057537274/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementbjFNVKgm9FMeta } from "/codebuild/output/src2057537274/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45alias8mfbH5TuyKMeta } from "/codebuild/output/src2057537274/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmuvREUT85qEMeta } from "/codebuild/output/src2057537274/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasDspKMpS3q1Meta } from "/codebuild/output/src2057537274/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editX7Ze7KIMGmMeta } from "/codebuild/output/src2057537274/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as index9pdfjHF2ESMeta } from "/codebuild/output/src2057537274/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmRx5kEq9iYxMeta } from "/codebuild/output/src2057537274/src/src/pages/card/create/confirm.vue?macro=true";
import { default as index7MULGNaooWMeta } from "/codebuild/output/src2057537274/src/src/pages/card/create/index.vue?macro=true";
import { default as indexc0UWakYrVWMeta } from "/codebuild/output/src2057537274/src/src/pages/card/index.vue?macro=true";
import { default as completeQ8yo8RerdjMeta } from "/codebuild/output/src2057537274/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirm0VsPpxJPUxMeta } from "/codebuild/output/src2057537274/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as register2pmB8GhoXrMeta } from "/codebuild/output/src2057537274/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactlQgcrRuxUfMeta } from "/codebuild/output/src2057537274/src/src/pages/contact.vue?macro=true";
import { default as _91id_93c4sZcZq8QpMeta } from "/codebuild/output/src2057537274/src/src/pages/coupon/[id].vue?macro=true";
import { default as index6tRCSpt9YRMeta } from "/codebuild/output/src2057537274/src/src/pages/coupon/index.vue?macro=true";
import { default as faqQfmZlN8wbzMeta } from "/codebuild/output/src2057537274/src/src/pages/faq.vue?macro=true";
import { default as indexz5WPMq3FB0Meta } from "/codebuild/output/src2057537274/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93Unrf4sbh89Meta } from "/codebuild/output/src2057537274/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexmsg4CqdQUhMeta } from "/codebuild/output/src2057537274/src/src/pages/index.vue?macro=true";
import { default as maintenance2AEEF7JpgBMeta } from "/codebuild/output/src2057537274/src/src/pages/maintenance.vue?macro=true";
import { default as completev2DwdtesiPMeta } from "/codebuild/output/src2057537274/src/src/pages/membership/complete.vue?macro=true";
import { default as confirm9wz8htBqkVMeta } from "/codebuild/output/src2057537274/src/src/pages/membership/confirm.vue?macro=true";
import { default as registermiQFl24idMMeta } from "/codebuild/output/src2057537274/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codehEKVYhFChlMeta } from "/codebuild/output/src2057537274/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmh8jKJQMPwYMeta } from "/codebuild/output/src2057537274/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registerBmopknZCkLMeta } from "/codebuild/output/src2057537274/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuNX9k7tAYLxMeta } from "/codebuild/output/src2057537274/src/src/pages/menu.vue?macro=true";
import { default as indexfghmxLoRbEMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/index.vue?macro=true";
import { default as indexGu4RAONUamMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sample9TV8NDG2F0Meta } from "/codebuild/output/src2057537274/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completejb1bfm4oH7Meta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeUyPGUYLZJGMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexxt9Hlpl1XoMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexDyw5etkJRnMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeFRTEnrdt44Meta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmHoSj3qBcfsMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexfv9pytWad8Meta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as update1KraNYjOlPMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmE67v5D0UwwMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexNsVU5V7dbmMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updateMhXjnPTPQAMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeROGx8M3VgxMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updateYlGBTpocrFMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as index8NdXhnSclkMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93iDl9JGAC1CMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completeh9QY3CuKJyMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmUTEP5MChwhMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completefwPfIBxlEeMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmgBOyplw5FSMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentoR2OaH5C5iMeta } from "/codebuild/output/src2057537274/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93xA2VRWqnzKMeta } from "/codebuild/output/src2057537274/src/src/pages/notice/[id].vue?macro=true";
import { default as indexWIu3Emm8NMMeta } from "/codebuild/output/src2057537274/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93ghmfhWH1eLMeta } from "/codebuild/output/src2057537274/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementhaANLnmKS0Meta } from "/codebuild/output/src2057537274/src/src/pages/pass-agreement.vue?macro=true";
import { default as completebWdAomDzjiMeta } from "/codebuild/output/src2057537274/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codebJj35cofkcMeta } from "/codebuild/output/src2057537274/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordNvQeBlPDXoMeta } from "/codebuild/output/src2057537274/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailfEkGy71HbuMeta } from "/codebuild/output/src2057537274/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codesMmDsXXzL8Meta } from "/codebuild/output/src2057537274/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexQ5HgmuHrlQMeta } from "/codebuild/output/src2057537274/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codegMzHvxFMzgMeta } from "/codebuild/output/src2057537274/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexkDedASHC0gMeta } from "/codebuild/output/src2057537274/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialmq5bVhVtOeMeta } from "/codebuild/output/src2057537274/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45alias8mfbH5TuyKMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmuvREUT85qEMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasDspKMpS3q1Meta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editX7Ze7KIMGmMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmRx5kEq9iYxMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: index7MULGNaooWMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeQ8yo8RerdjMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirm0VsPpxJPUxMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: register2pmB8GhoXrMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: index6tRCSpt9YRMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexz5WPMq3FB0Meta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexmsg4CqdQUhMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completev2DwdtesiPMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirm9wz8htBqkVMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registermiQFl24idMMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codehEKVYhFChlMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmh8jKJQMPwYMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerBmopknZCkLMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuNX9k7tAYLxMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexfghmxLoRbEMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexGu4RAONUamMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sample9TV8NDG2F0Meta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: index8NdXhnSclkMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completeh9QY3CuKJyMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmUTEP5MChwhMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completefwPfIBxlEeMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmgBOyplw5FSMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentoR2OaH5C5iMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexWIu3Emm8NMMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93ghmfhWH1eLMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completebWdAomDzjiMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codebJj35cofkcMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordNvQeBlPDXoMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailfEkGy71HbuMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codesMmDsXXzL8Meta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexQ5HgmuHrlQMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codegMzHvxFMzgMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexkDedASHC0gMeta || {},
    component: () => import("/codebuild/output/src2057537274/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src2057537274/src/src/pages/tutorial.vue")
  }
]